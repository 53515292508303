export enum LinkType {
    LEGACY_PAGE = "legacy-page",
    OTHER_APP = "other-app",
    UNKNOWN = "unknown",
}

export enum LinkItemLabel {
    ALCHEMY = "Alchemy",
    ALL_BRIEFS = "All Briefs",
    BRIEF_CREDITS = "Brief Credits",
    ENVELUP = "EnvelUp (Transactional Emails)",
    SEARCH = "Search",
    VIEW_EDIT_ALL_ISSUES = "View/Edit All Issues",
    CREATE_ISSUE = "Create Issue",
    SOURCES = "Sources",
    GCNL_MAPPING = "GCNL Mapping",
    CURRENT_ISSUE_STATUS = "Current Issue Status",
    ADVERTISING = "Advertising",
    LAUNCH = "Launch",
    TOOLS = "Tools",
    FORGOT_USERNAME = "Forgot User Name",
    FORGOT_PASSWORD = "Forgot Password",
    CHANGE_PASSWORD = "Change Password",
}

export type LinkItem = {
    label: LinkItemLabel;
    href: string;
    linkType?: LinkType;
};

export type MenuItems = LinkItem[];

export const alchemyItem: LinkItem = {
    label: LinkItemLabel.ALCHEMY,
    href: "/app/alchemy-homepage",
};

export const briefEditorItems: MenuItems = [
    {
        label: LinkItemLabel.ALL_BRIEFS,
        href: "/alquemie/jsp/brief/operations/briefEditor.jsp",
        linkType: LinkType.LEGACY_PAGE,
    },
    {
        label: LinkItemLabel.BRIEF_CREDITS,
        href: "/alquemie/jsp/briefuser/initialize.action",
        linkType: LinkType.LEGACY_PAGE,
    },
    {
        label: LinkItemLabel.ENVELUP,
        href: "/app/envelup",
    },
];

export const curateItems: MenuItems = [
    {
        label: LinkItemLabel.SEARCH,
        href: "/app/curate/#/home",
        linkType: LinkType.OTHER_APP,
    },
    {
        label: LinkItemLabel.VIEW_EDIT_ALL_ISSUES,
        href: "/app/curate-issue/issues/",
    },
    {
        label: LinkItemLabel.CREATE_ISSUE,
        href: "/app/curate-issue/issue-create/",
    },
    {
        label: LinkItemLabel.SOURCES,
        href: "/app/curate/#/sources",
        linkType: LinkType.OTHER_APP,
    },
    {
        label: LinkItemLabel.GCNL_MAPPING,
        href: "/app/curate/#/mappings",
        linkType: LinkType.OTHER_APP,
    },
    {
        label: LinkItemLabel.CURRENT_ISSUE_STATUS,
        href: "/alquemie/jsp/common/issueStatus.jsp",
        linkType: LinkType.LEGACY_PAGE,
    },
];

export const advertisingItem: LinkItem = {
    label: LinkItemLabel.ADVERTISING,
    href: "/jsp/advertising/index.jsp",
    linkType: LinkType.LEGACY_PAGE,
};

export const launchItem: LinkItem = {
    label: LinkItemLabel.LAUNCH,
    href: "/jsp/launchAdmin/launch.jsp",
    linkType: LinkType.LEGACY_PAGE,
};

export const toolsItem: LinkItem = {
    label: LinkItemLabel.TOOLS,
    href: "/jsp/utilities/tools.jsp",
    linkType: LinkType.LEGACY_PAGE,
};

export const forgotUsernameItem: LinkItem = {
    label: LinkItemLabel.FORGOT_USERNAME,
    href: "/forgotUsername.action",
    linkType: LinkType.LEGACY_PAGE,
};

export const forgotPasswordItem: LinkItem = {
    label: LinkItemLabel.FORGOT_PASSWORD,
    href: "/forgotPassword.action",
    linkType: LinkType.LEGACY_PAGE,
};

export const changePasswordItem: LinkItem = {
    label: LinkItemLabel.CHANGE_PASSWORD,
    href: "/changePasswordRequest.action",
    linkType: LinkType.LEGACY_PAGE,
};
