export enum Role {
    ADMIN = "admin",
    ADVERTISING_ADMINISTRATION = "ADVERTISING_ADMINISTRATION",
    ADVERTISING_OPERATION = "ADVERTISING_OPERATION",
    ARCHIVE_ADMINISTRATION = "ARCHIVE_ADMINISTRATION",
    BRIEF_ADMINISTRATION = "BRIEF_ADMINISTRATION",
    BRIEF_MANAGEMENT = "BRIEF_MANAGEMENT",
    BRIEF_OPERATIONS = "BRIEF_OPERATIONS",
    CMS_BETA_FEATURES = "CMS_BETA_FEATURES",
    COLLABCENTER_BETA = "COLLABCENTER_BETA",
    COPY_EDIT = "COPY_EDIT",
    EDITORIAL_ADMINISTRATION = "EDITORIAL_ADMINISTRATION",
    EXTERNAL_USER_MANAGEMENT = "EXTERNAL_USER_MANAGEMENT",
    feedback = "feedback",
    ISSUE_CREATION = "ISSUE_CREATION",
    LAUNCH_ADMINISTRATION = "LAUNCH_ADMINISTRATION",
    LETTER_MANAGEMENT = "LETTER_MANAGEMENT",
    MOCKUP = "mockup",
    PR_AD_ADMINISTRATION = "PR_AD_ADMINISTRATION",
    RADVERTISER = "radvertiser",
    RBRIEF = "rbrief",
    RINTERNAL = "rinternal",
    SALES = "SALES",
    SUBSCRIBER_DATA_MANAGEMENT = "SUBSCRIBER_DATA_MANAGEMENT",
    SUBSCRIBER_DETAILS_REPORT = "SUBSCRIBER_DETAILS_REPORT",
    SUBSCRIBER_EXPLORER = "SUBSCRIBER_EXPLORER",
    TAXONOMY_MANAGEMENT = "TAXONOMY_MANAGEMENT",
    TRIAL_LIST_MANAGEMENT = "TRIAL_LIST_MANAGEMENT",
    USER_ADMINISTRATION = "USER_ADMINISTRATION",
}

export type Roles = Role[];
