import { setupListeners } from "@reduxjs/toolkit/query";
import { configureStoreOptions } from "sb-material-components";
import { configureStore } from "@reduxjs/toolkit";
import { alquemieApiBase } from "./services/alquemieApi/alquemieApiBase";

export const createStore = () =>
    configureStore(
        configureStoreOptions([
            {
                apiReducerPath: alquemieApiBase.reducerPath,
                apiReducer: alquemieApiBase.reducer,
                apiMiddleware: alquemieApiBase.middleware,
            },
        ]),
    );

export const store = createStore();

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
