import { createSlice } from "@reduxjs/toolkit";

export type AuthState = {
    isLoggedIn: boolean;
};

const initialState: AuthState = { isLoggedIn: true };

const slice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        loginAction: (state) => {
            state.isLoggedIn = true;
        },
        logoutAction: (state) => {
            state.isLoggedIn = false;
        },
    },
});

export const { reducer } = slice;
export const { loginAction, logoutAction } = slice.actions;
export const selectIsLoggedIn = (state: { auth: AuthState }) => state.auth.isLoggedIn;
