import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

export type AlertMessagesSlice = {
    messages: {
        [id: MessageId]: Message;
    };
};

type MessageId = string;

export type Message = {
    text: string;
    type: "success" | "error" | "warning" | "info";
    persistent?: boolean;
};

export type MessageWithId = {
    id: MessageId;
    message: Message;
};

const initialState: AlertMessagesSlice = { messages: {} };

const slice = createSlice({
    name: "alertMessages",
    initialState,
    reducers: {
        addMessageAction: (state, action: PayloadAction<Message>) => {
            state.messages[uuidv4()] = action.payload;
        },
        addMessageWithIdAction: (state, action: PayloadAction<MessageWithId>) => {
            state.messages[action.payload.id] = action.payload.message;
        },
        removeMessageAction: (state, action: PayloadAction<MessageId>) => {
            delete state.messages[action.payload];
        },
    },
});

export const { reducer } = slice;
export const { addMessageAction, removeMessageAction, addMessageWithIdAction } = slice.actions;
export const selectAllMessages = (state: { alertMessages: AlertMessagesSlice }) =>
    Object.keys(state.alertMessages.messages).map((id) => ({
        id,
        message: state.alertMessages.messages[id],
    }));
