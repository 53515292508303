import { LinkItemLabel, LinkType } from "./alchemyLinks";

export function linkTarget(linkType: LinkType | undefined) {
    switch (linkType) {
        case LinkType.LEGACY_PAGE:
            return "_self";
        case LinkType.OTHER_APP:
            return "_blank";
        default:
            return "_self";
    }
}

export function embedUrl(linkLabel: LinkItemLabel) {
    return "embedpage/" + encodeURIComponent(linkLabel);
}
