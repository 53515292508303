import { createApi } from "@reduxjs/toolkit/query/react";
import { TagDescription } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { AlquemieApiError, alquemieBaseQuery } from "./alquemieBaseQuery";
import {
    authRequestHeaders,
    buildAuthRequestBody,
    CurrentUserResponse,
    isResponseStatusSuccessful,
    LoginRequest,
    LoginResponse,
    loginResponseHandler,
} from "./authenticationApi";

export enum ErrorTagTypes {
    UNAUTHORIZED = "UNAUTHORIZED",
    UNKNOWN_ERROR = "UNKNOWN_ERROR",
    CURRENT_USER = "CURRENT_USER",
}

export type AlchemyUserLite = {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
};

export function createAlquemieApiBase<T extends string>(tagTypes?: T[]) {
    return createApi({
        reducerPath: "alquemieApi",
        baseQuery: alquemieBaseQuery({ baseUrl: "/" }),
        tagTypes: [...Object.values(ErrorTagTypes).map((t) => t.valueOf()), ...(tagTypes ?? [])],
        endpoints: (builder) => ({
            login: builder.mutation<LoginResponse, LoginRequest>({
                query: (credentials) => ({
                    url: "jsp/utilities/tools.jsp",
                    method: "POST",
                    headers: authRequestHeaders,
                    body: buildAuthRequestBody(credentials),
                    responseHandler: loginResponseHandler,
                }),
                invalidatesTags: (response) => {
                    return response && response.successful === true ? [ErrorTagTypes.UNAUTHORIZED] : [];
                },
            }),
            logout: builder.mutation<Boolean, void>({
                query: () => ({
                    url: "jsp/logout.jsp",
                    method: "GET",
                    headers: authRequestHeaders,
                    responseHandler: isResponseStatusSuccessful,
                }),
            }),
            getCurrentUserInfo: builder.query<CurrentUserResponse, void>({
                query: () => "rest/alchemyuser/currentUser",
                transformResponse: (response: any) => ({
                    userId: response.userId,
                    roles: response.roles,
                    fullName: response.fullName,
                    emailAddress: response.emailAddress,
                }),
                providesTags: (result, error) =>
                    applyErrorTagTypes(() => [{ type: ErrorTagTypes.CURRENT_USER }], error),
            }),
        }),
    });
}

export const alquemieApiBase = createAlquemieApiBase();

export function applyErrorTagTypes<T extends string>(
    successTagProvider: () => ReadonlyArray<TagDescription<T>>,
    error?: AlquemieApiError,
) {
    if (error?.status === 401 || (error?.status === "PARSING_ERROR" && error?.originalStatus === 401)) {
        return [ErrorTagTypes.UNAUTHORIZED];
    }

    if (!!error) {
        return [ErrorTagTypes.UNKNOWN_ERROR];
    }

    return successTagProvider();
}

export const { useLoginMutation, useLogoutMutation, useGetCurrentUserInfoQuery } = alquemieApiBase;
