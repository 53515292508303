import React, { Fragment } from "react";
import { LoginOverlay } from "../Login/LoginOverlay";
import { AppHeaderBar } from "./AppHeaderBar";

export function AppHeader() {
    return (
        <Fragment>
            <LoginOverlay />
            <AppHeaderBar />
        </Fragment>
    );
}
