import { createTheme } from "@mui/material/styles";

const COLORS = {
    BACKGROUND: "#f2f2f2",
    SMARTBRIEF_BLUE: "#245993",
};

// A custom theme for this app
export const theme = createTheme({
    palette: {
        primary: {
            main: COLORS.SMARTBRIEF_BLUE,
        },
        background: {
            default: COLORS.BACKGROUND,
        },
    },
});
