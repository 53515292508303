import React, { ChangeEvent, FormEvent, useState } from "react";
import { Alert, Box, Dialog, DialogActions, DialogContent, Grid, Link, TextField } from "@mui/material";
import { useAuth } from "../../hooks/useAuth";
import { useDispatch } from "react-redux";
import { loginAction, logoutAction } from "../../services/authSlice";
import { LoginRequest, LoginResponse } from "../../services/alquemieApi/authenticationApi";
import { LoadingButton } from "@mui/lab";
import { useLoginMutation } from "../../services/alquemieApi/alquemieApiBase";

const initialFormState: LoginRequest = {
    username: "",
    password: "",
};

export function LoginOverlay() {
    const dispatch = useDispatch();
    const [formState, setFormState] = useState<LoginRequest>(initialFormState);
    const { isLoggedIn } = useAuth();
    const [loginError, setLoginError] = useState<Boolean>(false);
    const [changePassword, setChangePassword] = useState<Boolean>(false);
    const [login, { isLoading }] = useLoginMutation();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormState((prev) => ({ ...prev, [event.target.name]: event.target.value }));
    };

    const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            const loginResponse: LoginResponse = await login(formState).unwrap();
            if (loginResponse.successful) {
                setLoginError(false);
                dispatch(loginAction());
            }
        } catch (e: any) {
            setLoginError(true);
            setChangePassword(e.data.changePassword);
            dispatch(logoutAction());
        }
    };

    return (
        <Dialog open={!isLoggedIn} sx={{ zIndex: "snackbar" }}>
            <Box
                component="form"
                noValidate
                autoComplete="off"
                id="login-form"
                aria-label="Login"
                data-testid={"login-form"}
                onSubmit={onSubmit}
                sx={{ maxWidth: "280px" }}
            >
                <DialogContent>
                    <Grid container direction={"column"} spacing={2}>
                        <Grid item>
                            <TextField
                                autoFocus
                                required
                                fullWidth
                                id="username"
                                name="username"
                                label="Username"
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                required
                                fullWidth
                                id="password"
                                label="Password"
                                type="password"
                                name="password"
                                onChange={handleChange}
                                autoComplete="current-password"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container direction={"column"} spacing={2}>
                        <Grid item>
                            <LoadingButton
                                type={"submit"}
                                variant="contained"
                                size="large"
                                fullWidth={true}
                                color="primary"
                                loading={isLoading}
                                data-testid={"login-button"}
                            >
                                Login
                            </LoadingButton>
                        </Grid>
                        {loginError && <LoginError changePassword={changePassword} />}
                        <Grid container direction={"column"} alignItems={"center"} sx={{ pt: 2 }}>
                            <Grid item>
                                <Link
                                    href={"/app/alchemy-homepage/embedpage/" + encodeURIComponent("Forgot User Name")}
                                    target="_blank"
                                    color={"primary"}
                                    variant="caption"
                                >
                                    Forgot your username?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link
                                    href={"/app/alchemy-homepage/embedpage/" + encodeURIComponent("Forgot Password")}
                                    target="_blank"
                                    color={"primary"}
                                    variant="caption"
                                >
                                    Forgot your password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link
                                    href={"/app/alchemy-homepage/embedpage/" + encodeURIComponent("Change Password")}
                                    target="_blank"
                                    color={"primary"}
                                    variant="caption"
                                >
                                    Want to change your password?
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Box>
        </Dialog>
    );
}

const LoginError = (props: { changePassword: Boolean }) => {
    return (
        <Grid item>
            {props.changePassword ? (
                <Alert severity="error" aria-label="password-expired" data-testid={"password-expired"}>
                    Your password has expired, please change it before proceeding.
                </Alert>
            ) : (
                <Alert severity="error" aria-label="login-error" data-testid={"login-error"}>
                    Login failed, please try again.
                </Alert>
            )}
        </Grid>
    );
};
