import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { BaseQueryFn } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { FetchArgs, FetchBaseQueryArgs, FetchBaseQueryMeta } from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import { v4 as uuidv4 } from "uuid";

export type ErrorId = string;

export type AlquemieApiError = FetchBaseQueryError & {
    id: ErrorId;
};

export function alquemieBaseQuery(
    args: FetchBaseQueryArgs,
): BaseQueryFn<string | FetchArgs, unknown, AlquemieApiError, {}, FetchBaseQueryMeta> {
    const baseQuery = fetchBaseQuery(args);

    return async (args, api) => {
        const result = await baseQuery(args, api, {});
        if (result.error !== undefined) {
            return { ...result, error: { ...result.error, id: uuidv4() } };
        }

        return result;
    };
}
