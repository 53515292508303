import React from "react";
import { AppBar, Box, Button, Link, Stack, Toolbar, Typography } from "@mui/material";
import { HeaderMenu } from "./HeaderMenu";
import { advertisingItem, briefEditorItems, curateItems, launchItem, LinkItem, toolsItem } from "./alchemyLinks";
import { embedUrl, linkTarget } from "./linkHelper";
import { UserProfileMenu } from "./UserProfileMenu";

export function AppHeaderBar() {
    return (
        <AppBar position="sticky" aria-label="app header" data-testid={"app-header"}>
            <Toolbar>
                <Typography component="div" sx={{ flexGrow: 1 }} role={"heading"} aria-level={1}>
                    <Link href="/app/alchemy-homepage/" variant="h6" underline="none" color="white">
                        Alchemy
                    </Link>
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Stack direction={"row"}>
                    <HeaderMenu title="Brief Editor" menuItems={briefEditorItems} />
                    <HeaderMenu title="Curate" menuItems={curateItems} />
                    <EmbeddedHeaderLink linkItem={advertisingItem} />
                    <EmbeddedHeaderLink linkItem={launchItem} />
                    <EmbeddedHeaderLink linkItem={toolsItem} />
                    <UserProfileMenu />
                </Stack>
            </Toolbar>
        </AppBar>
    );
}

function EmbeddedHeaderLink(props: { linkItem: LinkItem }) {
    return (
        <Button
            href={"/app/alchemy-homepage/" + embedUrl(props.linkItem.label)}
            target={linkTarget(props.linkItem.linkType)}
            variant={"contained"}
            disableElevation
        >
            {props.linkItem.label}
        </Button>
    );
}

export function HeaderLink(props: { linkItem: LinkItem }) {
    return (
        <Button
            href={props.linkItem.href}
            target={linkTarget(props.linkItem.linkType)}
            variant={"text"}
            disableElevation
        >
            {props.linkItem.label}
        </Button>
    );
}
