import React from "react";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "./AppRoutes";
import { store } from "./app/store";
import { theme } from "sb-material-components";

export function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Provider store={store}>
                <BrowserRouter basename={"/app/alchemy-homepage"}>
                    <AppRoutes />
                </BrowserRouter>
            </Provider>
        </ThemeProvider>
    );
}
