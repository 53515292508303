import React, { Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import { DialogContent, DialogTitle, Grid, LinearProgress, Skeleton } from "@mui/material";
import {
    advertisingItem,
    briefEditorItems,
    changePasswordItem,
    curateItems,
    forgotPasswordItem,
    forgotUsernameItem,
    launchItem,
    MenuItems,
    toolsItem,
} from "../AppHeader/alchemyLinks";
import { useAuth } from "../../hooks/useAuth";

const EmbeddingPageSkeleton = () => (
    <Grid item xs={12}>
        <Skeleton variant="text" animation={false} aria-label={"alchemy page skeleton"} />
    </Grid>
);

const findItem = (menuItems: MenuItems, pagename: string) =>
    menuItems
        .filter((b) => b.label === pagename)
        .map((b) => b.href)
        .at(0);

function getJspPageSrc(pagename: string) {
    switch (pagename) {
        case advertisingItem.label:
            return advertisingItem.href;
        case launchItem.label:
            return launchItem.href;
        case toolsItem.label:
            return toolsItem.href;
        case "All Briefs":
            return findItem(briefEditorItems, pagename);
        case "Brief Credits":
            return findItem(briefEditorItems, pagename);
        case "Current Issue Status":
            return findItem(curateItems, pagename);
        case forgotUsernameItem.label:
            return forgotUsernameItem.href;
        case forgotPasswordItem.label:
            return forgotPasswordItem.href;
        case changePasswordItem.label:
            return changePasswordItem.href;
        default:
            console.log("No such page exists!");
            break;
    }
}

const EmbeddingPageContent = () => {
    const { pagename = "" }: { pagename?: string } = useParams<"pagename">();

    const [loading, setLoading] = useState(true);
    const handleOnLoad = () => setLoading(false);

    return (
        <Fragment>
            {loading && (
                <DialogTitle>
                    <LinearProgress sx={{ mt: 1 }} variant={"indeterminate"} value={100} />
                </DialogTitle>
            )}
            <DialogContent data-testid={"alchemy-page-dialog"}>
                <iframe
                    style={{ border: 0, width: "100%", height: "250vh" }}
                    src={getJspPageSrc(pagename)}
                    data-testid={"iframe-alchemy-page"}
                    onLoad={handleOnLoad}
                    title={pagename}
                />
            </DialogContent>
        </Fragment>
    );
};

export const EmbeddingPage = () => {
    const { isLoggedIn } = useAuth();
    if (isLoggedIn) {
        return <EmbeddingPageContent />;
    } else {
        return <EmbeddingPageSkeleton />;
    }
};
