import React, { Fragment, MouseEvent, useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { logoutAction } from "../../services/authSlice";
import { useLogoutMutation } from "../../services/alquemieApi/alquemieApiBase";

export function UserProfileMenu() {
    const dispatch = useDispatch();
    const [logout, { isLoading }] = useLogoutMutation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        try {
            const logoutSuccessful = await logout().unwrap();
            if (logoutSuccessful) {
                dispatch(logoutAction());
            }
        } catch (e) {
            dispatch(logoutAction());
        }
        setAnchorEl(null);
    };

    return (
        <Fragment>
            <IconButton
                size="large"
                aria-label="user menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                role="menuitem"
                data-testid={"user-menu"}
            >
                <AccountCircle />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleLogout} disabled={isLoading} role={"menuitem"}>
                    Logout
                </MenuItem>
            </Menu>
        </Fragment>
    );
}
