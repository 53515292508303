export enum BannerStatus {
    HIDDEN,
    ERROR,
    WARNING,
    INFO,
}

export enum BannerTextColor {
    DEFAULT = "rgba(0, 0, 0, 0)",
    ERROR = "rgb(132, 32, 41)",
    WARNING = "rgb(102, 77, 3)",
    INFO = "rgb(8, 66, 152)",
}

export enum BannerBackgroundColor {
    DEFAULT = "rgba(0, 0, 0, 0)",
    ERROR = "rgb(248, 215, 218)",
    WARNING = "rgb(255, 243, 205)",
    INFO = "rgb(207, 226, 255)",
}
