import React, { FC } from "react";
import { Box, Divider } from "@mui/material";
import { lightGrey } from "../../utils/customHomePageStyles";

export const ServerInfos: FC<{
    error: boolean;
    serverName: string | undefined;
}> = ({ error, serverName }) => {
    return (
        <Box data-testid={"server-infos"}>
            {!error && serverName && (
                <Divider data-testid={"connected-server-name"}>
                    <Box component="span" sx={lightGrey} fontSize={"small"}>
                        Connected to {serverName}
                    </Box>
                </Divider>
            )}
        </Box>
    );
};
