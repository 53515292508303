import React, { VoidFunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";
import { HomePage } from "./features/HomePage/HomePage";
import { Box } from "@mui/material";
import { AlertMessages, AppHeader } from "sb-material-components";
import { EmbeddingPage } from "sb-material-components";

export const AppRoutes: VoidFunctionComponent = () => {
    return (
        <main>
            <AppHeader />
            <AlertMessages />
            <Box m={2}>
                <Routes>
                    <Route index element={<HomePage />} />
                    <Route path="/embedpage/:pagename" element={<EmbeddingPage />} />
                </Routes>
            </Box>
        </main>
    );
};
