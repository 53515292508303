export interface LoginRequest {
    username: string;
    password: string;
}

export interface CurrentUserResponse {
    userId: string;
    roles: string[];
    fullName: string | undefined;
    emailAddress: string;
}

export interface LoginResponse {
    successful: boolean;
    changePassword: boolean;
}

export const authRequestHeaders = {
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, PATCH, OPTIONS",
    "Access-Control-Allow-Headers": "X-Requested-With, content-type, Authorization",
    "Access-Control-Max-Age": "86400",
    "Content-Type": "application/x-www-form-urlencoded",
};

export const isResponseStatusSuccessful = async (response: Response): Promise<Boolean> => {
    return response.status >= 200 && response.status <= 299;
};

export const loginResponseHandler = async (response: Response): Promise<LoginResponse> => {
    return {
        successful: response.status >= 200 && response.status <= 299,
        changePassword: response.headers.get("password-expired")
            ? response.headers.get("password-expired") === "true"
            : false,
    };
};

export const buildAuthRequestBody = (credentials: LoginRequest): string => {
    const username = encodeURIComponent(credentials.username);
    const password = encodeURIComponent(credentials.password);
    return `username=${username}&password=${password}`;
};
