import React, { useEffect } from "react";
import { useAlertMessages } from "../../hooks/useAlertMessages";
import { useDispatch } from "react-redux";
import { Alert, Stack } from "@mui/material";
import { removeMessageAction } from "../../services/alertMessagesSlice";

export function AlertMessages() {
    const { alertMessages } = useAlertMessages();
    const dispatch = useDispatch();

    useEffect(() => {
        const timeout = setTimeout(() => {
            alertMessages.filter((m) => !m.message.persistent).forEach((m) => dispatch(removeMessageAction(m.id)));
        }, 5000);
        return () => {
            window.clearTimeout(timeout);
        };
    }, [alertMessages, dispatch]);

    return !alertMessages || alertMessages.length === 0 ? null : (
        <Stack
            spacing={2}
            sx={{ position: "fixed", bottom: 10, right: 10, zIndex: "snackbar" }}
            data-testid={"alert-messages"}
        >
            {alertMessages.map((m) => (
                <Alert
                    variant="filled"
                    onClose={() => dispatch(removeMessageAction(m.id))}
                    severity={m.message.type}
                    sx={{ width: "100%" }}
                    key={m.id}
                >
                    {m.message.text}
                </Alert>
            ))}
        </Stack>
    );
}
