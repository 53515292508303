import { isRejectedWithValue, Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import { logoutAction } from "../services/authSlice";

export const authenticationMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action) && action?.payload?.originalStatus === 401) {
        api.dispatch(logoutAction());
    }

    return next(action);
};
