import { applyErrorTagTypes } from "sb-material-components";
import { alquemieApiBase, TagTypes } from "./alquemieApiBase";

export interface ConnectedServerInfos {
    serverName: string;
}

const userApi = alquemieApiBase.injectEndpoints({
    endpoints: (builder) => ({
        getConnectedServerInfo: builder.query<ConnectedServerInfos, void>({
            query: () => "rest/alchemyuser/connected-server",
            transformResponse: (response: any) => ({
                serverName: response?.serverName || "",
            }),
            providesTags: (result, error) => applyErrorTagTypes(() => [{ type: TagTypes.CurrentUser }], error),
        }),
    }),
});

export const { useGetConnectedServerInfoQuery } = userApi;
