import React, { FC, Fragment } from "react";
import { Button, Grid, Link as MaterialLink, Stack } from "@mui/material";
import { HomeCard } from "./HomeCard";
import { AdUnits, BusinessCenter, ForwardOutlined, ForwardToInbox, LibraryBooks } from "@mui/icons-material";
import { useGetConnectedServerInfoQuery } from "../../app/services/alquemieApi/userApi";
import { embedUrl, LinkItemLabel, useGetCurrentUserInfoQuery } from "sb-material-components";
import { ServerInfos } from "./ServerInfos";
import Typography from "@mui/material/Typography";

const cardFontSize = 40;

export const HomePage: FC = () => {
    useGetCurrentUserInfoQuery();
    const {
        data: serverInfos,
        isLoading: serverInfosIsLoading,
        isError: serverInfosIsError,
    } = useGetConnectedServerInfoQuery();

    return (
        <Fragment>
            <Stack mx={2} my={5}>
                <Grid container justifyContent="center" spacing={2} alignItems="center" sx={{ marginBottom: 10 }}>
                    <Grid item>
                        <Grid container justifyContent="center" spacing={2} alignItems="center">
                            <Grid item>
                                <HomeCard title="Brief Editor" cardWidth={290} cardHeight={300}>
                                    <BusinessCenter sx={{ fontSize: cardFontSize, width: "auto", marginBottom: 2 }} />
                                    <Button
                                        component={MaterialLink}
                                        href={"/app/alchemy-homepage/" + embedUrl(LinkItemLabel.ALL_BRIEFS)}
                                        size="medium"
                                        aria-label="all briefs button"
                                    >
                                        All Briefs
                                    </Button>
                                    <Button
                                        component={MaterialLink}
                                        href={"/app/alchemy-homepage/" + embedUrl(LinkItemLabel.BRIEF_CREDITS)}
                                        size="medium"
                                        aria-label="brief credits button"
                                    >
                                        Brief Credits
                                    </Button>
                                    <Button
                                        component={MaterialLink}
                                        href={"/app/envelup"}
                                        size="medium"
                                        aria-label="envelup"
                                        sx={{ display: "block", textAlign: "center" }}
                                    >
                                        EnvelUp
                                        <Typography
                                            variant={"caption"}
                                            component={"div"}
                                            color="text.secondary"
                                            sx={{ fontSize: "10px" }}
                                        >
                                            (Transactional Emails)
                                        </Typography>
                                    </Button>
                                </HomeCard>
                            </Grid>
                            <Grid item>
                                <ForwardOutlined sx={{ fontSize: cardFontSize, width: "auto" }} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container justifyContent="center" spacing={2} alignItems="center">
                            <Grid item>
                                <HomeCard title="Curate" cardWidth={290} cardHeight={300}>
                                    <LibraryBooks sx={{ fontSize: cardFontSize, width: "auto", marginBottom: 2 }} />
                                    <Button
                                        component={MaterialLink}
                                        href={"/app/curate/#/home"}
                                        size="medium"
                                        aria-label="curate search button"
                                        target={"_blank"}
                                    >
                                        Search
                                    </Button>
                                    <Button
                                        component={MaterialLink}
                                        href={"/app/curate-issue/issues/"}
                                        size="medium"
                                        aria-label="view edit all issues button"
                                    >
                                        View/Edit All Issues
                                    </Button>
                                    <Button
                                        component={MaterialLink}
                                        href={"/app/curate-issue/issue-create/"}
                                        size="medium"
                                        aria-label="create issue button"
                                    >
                                        Create Issue
                                    </Button>
                                </HomeCard>
                            </Grid>
                            <Grid item>
                                <ForwardOutlined sx={{ fontSize: cardFontSize, width: "auto" }} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container justifyContent="center" spacing={2} alignItems="center">
                            <Grid item>
                                <HomeCard title="Advertising" cardWidth={290} cardHeight={300}>
                                    <AdUnits sx={{ fontSize: cardFontSize, width: "auto", marginBottom: 2 }} />
                                    <Button
                                        component={MaterialLink}
                                        href={"/app/voodoo/#/home"}
                                        size="medium"
                                        aria-label="voodoo manager button"
                                        target={"_blank"}
                                    >
                                        Voodoo Manager
                                    </Button>
                                    <Button
                                        component={MaterialLink}
                                        href={"/app/alchemy-homepage/" + embedUrl(LinkItemLabel.ADVERTISING)}
                                        size="medium"
                                        aria-label="advertising issues button"
                                    >
                                        Issues
                                    </Button>
                                    <Button
                                        component={MaterialLink}
                                        href={"/alquemie/app/suppressionlist/#/manage"}
                                        size="medium"
                                        aria-label="suppression lists button"
                                        target={"_blank"}
                                    >
                                        Suppression Lists
                                    </Button>
                                </HomeCard>
                            </Grid>
                            <Grid item>
                                <ForwardOutlined sx={{ fontSize: cardFontSize, width: "auto" }} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container justifyContent="center" spacing={2} alignItems="center">
                            <Grid item>
                                <HomeCard title="Launch" cardWidth={290} cardHeight={300}>
                                    <ForwardToInbox sx={{ fontSize: cardFontSize, width: "auto", marginBottom: 2 }} />
                                    <Button
                                        component={MaterialLink}
                                        href={"/app/alchemy-homepage/" + embedUrl(LinkItemLabel.LAUNCH)}
                                        size="medium"
                                        aria-label="manual launch issues button"
                                        sx={{ textAlign: "center" }}
                                    >
                                        Manual Launch Issues
                                    </Button>
                                </HomeCard>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Stack>
            {!serverInfosIsLoading && <ServerInfos error={serverInfosIsError} serverName={serverInfos?.serverName} />}
        </Fragment>
    );
};
