import { isRejectedWithValue, Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import { addMessageAction, addMessageWithIdAction, Message } from "../services/alertMessagesSlice";

export const errorAlertsMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
    const isParsingError = (status: any) => {
        return status === "PARSING_ERROR";
    };

    const successfulResponseCouldNotBeParsed = (originalStatus: any, status: any) => {
        const isHttpSuccess = originalStatus >= 200 && originalStatus <= 299;
        return isHttpSuccess && isParsingError(status);
    };

    const errorResponseCouldNotBeParsed = (originalStatus: any, status: any) => {
        const isHttpError = originalStatus < 200 || originalStatus >= 300;
        return isHttpError && isParsingError(status);
    };

    const buildMessage = () => {
        let message: Message;
        if (successfulResponseCouldNotBeParsed(action?.payload?.originalStatus, action?.payload?.status)) {
            message = {
                type: "success",
                text: action.payload.data,
                persistent: false,
            };
        } else {
            message = {
                type: "error",
                text: "",
                persistent: action?.payload?.status === 404 || action?.payload?.originalStatus === 404,
            };
            if (errorResponseCouldNotBeParsed(action?.payload?.originalStatus, action?.payload?.status)) {
                message.text = action.payload.data;
            } else {
                message.text = `Error: ${JSON.stringify(action.type)} failed with status ${
                    action.payload?.originalStatus || action.payload?.status
                }`;
            }
        }
        return message;
    };

    if (isRejectedWithValue(action) && action?.payload?.originalStatus !== 401) {
        const message = buildMessage();
        const newAction = action.payload?.id
            ? addMessageWithIdAction({ id: action.payload?.id, message })
            : addMessageAction(message);
        api.dispatch(newAction);
    }

    return next(action);
};
