import React, { FC, Fragment, SyntheticEvent, useState } from "react";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import { embedUrl } from "./linkHelper";
import { LinkItem, LinkType, MenuItems } from "./alchemyLinks";
import { KeyboardArrowDown } from "@mui/icons-material";

export const HeaderMenu: FC<{ title: string; menuItems?: MenuItems }> = ({ title, menuItems }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = !!anchorEl;

    const openMenu = (event: SyntheticEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleItemClick = (link: LinkItem) => {
        setAnchorEl(null);
        switch (link.linkType) {
            case LinkType.LEGACY_PAGE:
                window.location.href = "/app/alchemy-homepage/" + embedUrl(link.label);
                return;
            case LinkType.OTHER_APP:
                window.open(link.href, "_blank");
                return;
            case undefined:
            case LinkType.UNKNOWN:
                window.location.href = link.href;
                return;
            default:
                assertAllLinkTypesHandled(link.linkType);
        }
    };

    return (
        <Fragment>
            <Button
                sx={{ justifyContent: "left" }}
                onClick={openMenu}
                variant={"contained"}
                role="menubutton"
                disableElevation
            >
                <Box sx={{ width: "100%" }}>{title}</Box>
                <KeyboardArrowDown />
            </Button>
            <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
                {menuItems?.map((linkItem) => (
                    <MenuItem onClick={() => handleItemClick(linkItem)} key={linkItem.label}>
                        {linkItem.label}
                    </MenuItem>
                ))}
            </Menu>
        </Fragment>
    );
};

function assertAllLinkTypesHandled(x: never): never {
    throw new Error("unhandled link type");
}
