import { configureStore, Middleware, Reducer, ReducersMapObject } from "@reduxjs/toolkit";
import { reducer as alertMessagesReducer } from "./services/alertMessagesSlice";
import { reducer as authReducer } from "./services/authSlice";
import { authenticationMiddleware } from "./utils/authenticationMiddleware";
import { alquemieApiBase } from "./services/alquemieApi/alquemieApiBase";
import { errorAlertsMiddleware } from "./utils/errorAlertsMiddleware";
import { ConfigureStoreOptions } from "@reduxjs/toolkit/src/configureStore";

export type ApiBase = {
    apiReducerPath: string;
    apiReducer: Reducer;
    apiMiddleware: Middleware;
};

type ApiReducers = {
    [key: string]: Reducer;
};

function defaultApiBase() {
    return {
        apiReducer: { [alquemieApiBase.reducerPath]: alquemieApiBase.reducer },
        apiMiddleware: alquemieApiBase.middleware,
    };
}

const getApiMiddlewares = (apis?: ApiBase[]): Middleware[] => {
    const apiMiddlewares = [];
    if (apis?.length) {
        apis.map((api) => apiMiddlewares.push(api.apiMiddleware));
    } else {
        apiMiddlewares.push(defaultApiBase().apiMiddleware);
    }
    return apiMiddlewares;
};

const getApiReducers = (apis?: ApiBase[]) => {
    const apiReducers: ApiReducers = {};
    if (apis?.length) {
        apis.forEach((api) => {
            apiReducers[api.apiReducerPath] = api.apiReducer;
        });
    } else {
        apiReducers[alquemieApiBase.reducerPath] = alquemieApiBase.reducer;
    }
    return apiReducers;
};

export function configureStoreOptions(
    apis?: ApiBase[],
    reducers?: ReducersMapObject,
    middleware?: Middleware[],
): ConfigureStoreOptions {
    const combinedMiddleware: Middleware[] = [
        ...getApiMiddlewares(apis),
        authenticationMiddleware,
        errorAlertsMiddleware,
        ...[...(middleware ?? [])],
    ];
    const combinedReducers = {
        ...getApiReducers(apis),
        auth: authReducer,
        alertMessages: alertMessagesReducer,
        ...(reducers ?? {}),
    };

    return {
        reducer: combinedReducers,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                immutableCheck: { warnAfter: 128 },
                serializableCheck: { warnAfter: 128 },
            }).concat(combinedMiddleware),
    };
}

export function createStore() {
    return configureStore(configureStoreOptions());
}
