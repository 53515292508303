import React, { FC } from "react";
import { Card, CardContent, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";

export const HomeCard: FC<{ title: string; cardWidth: number; cardHeight: number }> = ({
    title,
    children,
    cardWidth,
    cardHeight,
}) => {
    return (
        <Card sx={{ width: cardWidth, height: cardHeight }}>
            <CardContent>
                <Typography align="center" variant="h5" component="div">
                    {title}
                </Typography>
                <Stack spacing={1} my={3} px={2}>
                    {children}
                </Stack>
            </CardContent>
        </Card>
    );
};
